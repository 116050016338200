:root {
  --border-color: #d0d7de;
  --card-background-color: #f8faff;
  --card-text-color: #686767;
  --card-header-background-color: rgb(247, 247, 247);
  --code-background-color: rgb(45, 51, 59);
  --code-text-color: rgb(250, 250, 250);
  --header-text-color: rgb(233, 228, 228);
  --light-text-color: #9aa0b0;
  --link-color: #24292f !important;
  --main-background-color: #e8eaf3;
  --main-text-color: #333333;
  --navbar-bg-color: #f8faff;
  --quote-background-color: rgb(247, 247, 247);
  --text-selection-foreground-color: #000;
  --text-selection-highlight-color: rgb(212, 212, 212);
  --hero-color: #0d47a1;
}
