body {
  font-family: 'Rubik', sans-serif;
  min-height: 75rem;
  background-color: var(--main-background-color);
  color: var(--main-text-color);
}

::selection {
  /* WebKit/Blink Browsers */
  background: var(--text-selection-highlight-color);
  color: var(--text-selection-foreground-color);
}
::-moz-selection {
  /* Gecko Browsers */
  background: var(--text-selection-highlight-color);
  color: var(--text-selection-foreground-color);
}
a:not(.btn) {
  color: var(--link-color);
}
a:not(.btn):not(.nav-link22):hover {
  text-decoration: underline;
}

.card {
  background-color: var(--card-background-color);
  border: 1px solid var(--border-color, 0.125);
  color: var(--main-text-color);
  a:not(.btn) {
    color: var(--link-color);
  }
  a:not(.btn):not(.page-link):hover {
    text-decoration: none;
  }
}
.card-footer {
  background-color: var(--card-header-background-color);
  color: var(--main-text-color);
  border-top: 1px solid var(--border-color, 0.125);
}
.card-text {
  color: var(--card-text-color);
}

.border-top {
  border-top: 1px solid var(--border-color, 0.125) !important;
}
.border-bottom {
  border-bottom: 1px solid var(--border-color, 0.125) !important;
}
.border-end {
  border-right: 1px solid var(--border-color, 0.125) !important;
}
.border-start {
  border-left: 1px solid var(--border-color, 0.125) !important;
}

#social-links {
  a:not(.btn):hover {
    text-decoration: none;
  }
}

.navbar-light {
  .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28152,150, 148, 1%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  }

  .navbar-toggler {
    border: 1px solid var(--light-text-color);
  }
}

.navbar-light .navbar-nav {
  .nav-link {
    color: var(--light-text-color);
    &:hover {
      color: var(--light-text-color);
    }
  }
  .nav-link.active,
  .show > .nav-link {
    color: var(--main-text-color);
  }
}

a.underline {
  text-decoration: none;
  color: inherit;
  line-height: 1;
  & {
    background: linear-gradient(0deg, var(--link-color), var(--link-color)) no-repeat right bottom / 0 var(--bg-h);
    transition: background-size 350ms;
    --bg-h: 2px;
  }
  &:where(:hover, :focus-visible) {
    background-size: 100% var(--bg-h);
    background-position-x: left;
  }
}

.fixed-top {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: var(--navbar-bg-color);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.5);
}

.anchor hidden {
  display: block;
}

h1,
h2,
h3,
h4 {
  margin-top: 1.5em;
  margin-bottom: 0em;
}

#summary {
  background-color: var(--hero-color);
  color: #fff;
  .text-muted {
    color: var(--light-text-color) !important;
  }
}
