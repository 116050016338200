:root {
  --border-color: #d0d7de;
  --card-background-color: #f8faff;
  --card-text-color: #686767;
  --card-header-background-color: #f7f7f7;
  --code-background-color: #2d333b;
  --code-text-color: #fafafa;
  --header-text-color: #e9e4e4;
  --light-text-color: #9aa0b0;
  --main-background-color: #e8eaf3;
  --main-text-color: #333;
  --navbar-bg-color: #f8faff;
  --quote-background-color: #f7f7f7;
  --text-selection-foreground-color: #000;
  --text-selection-highlight-color: #d4d4d4;
  --hero-color: #0d47a1;
  --link-color: #24292f !important;
}

@media (prefers-color-scheme: dark) {
  :root {
    --border-color: #454c56;
    --card-background-color: #0d1116;
    --card-text-color: #6d757d;
    --card-header-background-color: #0d1116;
    --code-background-color: #2d333b;
    --code-text-color: #fafafa;
    --header-text-color: #e9e4e4;
    --light-text-color: #8a949e;
    --main-background-color: #02040a;
    --main-text-color: #c9d1d9;
    --navbar-bg-color: #161b22;
    --text-selection-foreground-color: #000;
    --text-selection-highlight-color: #d4d4d4;
    --hero-color: #454c56;
    --link-color: #57a6ff !important;
  }
}

body {
  min-height: 75rem;
  background-color: #e8eaf3;
  background-color: var(--main-background-color);
  color: #333;
  color: var(--main-text-color);
  font-family: Rubik, sans-serif;
}

::-moz-selection {
  background: #d4d4d4;
  background: var(--text-selection-highlight-color);
  color: #000;
  color: var(--text-selection-foreground-color);
}

::selection {
  background: #d4d4d4;
  background: var(--text-selection-highlight-color);
  color: #000;
  color: var(--text-selection-foreground-color);
}

::-moz-selection {
  background: #d4d4d4;
  background: var(--text-selection-highlight-color);
  color: #000;
  color: var(--text-selection-foreground-color);
}

a:not(.btn) {
  color: #24292f;
  color: var(--link-color);
}

a:not(.btn):not(.nav-link22):hover {
  text-decoration: underline;
}

.card {
  background-color: #f8faff;
  background-color: var(--card-background-color);
  border: 1px solid #d0d7de;
  border: 1px solid var(--border-color, .125);
  color: #333;
  color: var(--main-text-color);
}

.card a:not(.btn) {
  color: #24292f;
  color: var(--link-color);
}

.card a:not(.btn):not(.page-link):hover {
  text-decoration: none;
}

.card-footer {
  background-color: #f7f7f7;
  background-color: var(--card-header-background-color);
  color: #333;
  color: var(--main-text-color);
  border-top: 1px solid #d0d7de;
  border-top: 1px solid var(--border-color, .125);
}

.card-text {
  color: #686767;
  color: var(--card-text-color);
}

.border-top {
  border-top: 1px solid #d0d7de !important;
  border-top: 1px solid var(--border-color, .125) !important;
}

.border-bottom {
  border-bottom: 1px solid #d0d7de !important;
  border-bottom: 1px solid var(--border-color, .125) !important;
}

.border-end {
  border-right: 1px solid #d0d7de !important;
  border-right: 1px solid var(--border-color, .125) !important;
}

.border-start {
  border-left: 1px solid #d0d7de !important;
  border-left: 1px solid var(--border-color, .125) !important;
}

#social-links a:not(.btn):hover {
  text-decoration: none;
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28152,150, 148, 1%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-light .navbar-toggler {
  border: 1px solid #9aa0b0;
  border: 1px solid var(--light-text-color);
}

.navbar-light .navbar-nav .nav-link, .navbar-light .navbar-nav .nav-link:hover {
  color: #9aa0b0;
  color: var(--light-text-color);
}

.navbar-light .navbar-nav .nav-link.active, .navbar-light .navbar-nav .show > .nav-link {
  color: #333;
  color: var(--main-text-color);
}

a.underline {
  color: inherit;
  background: linear-gradient(0deg, #24292f, #24292f) no-repeat right bottom / 0 var(--bg-h);
  background: linear-gradient(0deg, var(--link-color), var(--link-color)) no-repeat right bottom / 0 var(--bg-h);
  --bg-h: 2px;
  line-height: 1;
  text-decoration: none;
  transition: background-size .35s;
}

a.underline:where(:hover, :focus-visible) {
  background-size: 100% var(--bg-h);
  background-position-x: left;
}

.fixed-top {
  background-color: #f8faff;
  background-color: var(--navbar-bg-color);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .5);
}

.anchor hidden {
  display: block;
}

h1, h2, h3, h4 {
  margin-top: 1.5em;
  margin-bottom: 0;
}

#summary {
  background-color: #0d47a1;
  background-color: var(--hero-color);
  color: #fff;
}

#summary .text-muted {
  color: #9aa0b0 !important;
  color: var(--light-text-color) !important;
}

/*# sourceMappingURL=main.css.map */
