@media (prefers-color-scheme: dark) {
  :root {
    --border-color: #454c56;
    --card-background-color: #0d1116;
    --card-text-color: #6d757d;
    --card-header-background-color: #0d1116;
    --code-background-color: rgb(45, 51, 59);
    --code-text-color: rgb(250, 250, 250);
    --header-text-color: rgb(233, 228, 228);
    --light-text-color: #8a949e;
    --link-color: #57a6ff !important;
    --main-background-color: #02040a;
    --main-text-color: #c9d1d9;
    --navbar-bg-color: #161b22;
    --text-selection-foreground-color: #000;
    --text-selection-highlight-color: rgb(212, 212, 212);
    --hero-color: #454c56;
  }
}
